*{
  overflow-x: hidden;
}

.banner {
  background-image: url("../../Images/banner1.1.png");
  width: 100%;
  background-size: cover;
  margin-top: 5%;
  height: 80vh;

}

.banner2 {
  background-image: url("../../Images/banner2.png");
  width: 100%;
  background-size: cover;
  margin-top: 5%;
  height: 80vh;

}

@media screen and (max-width: 780px) {
  .banner {
    background-image: url("../../Images/banner77.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 100vw;
    height: 55vh;
    margin-top: 20%;
  }

  .banner2 {
    background-image: url("../../Images/banner88.0.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 100vw;
    height: 55vh;
    margin-top: 2%;
  }

  .logo1 {
    margin-top: 5%!important;
    display: flex;
    flex-direction: column-reverse;
    width: 100vw;
    height: 20vh!important;
 
  }

  .design{

    border-radius: 50%!important;
    margin-left: 0%!important;
    width: 30vw!important;

  }
  .mob{
    width: 150vw!important;
    height: 100vh!important;
    padding-right: 0%;
    margin: 2%;
    animation: marquee 10s linear infinite;
    justify-content: space-evenly!important;

  }
  .words{
    margin-top: 4%;
    width: 100vw!important;
    height: 20vh!important;
    text-align: center;
    margin-left: -1%!important;
    overflow-y: hidden!important;
  }

  .ai-img {
    margin-top: 18%!important;
    margin-left: 27%!important;
    width: 45vw;
    height: 45vw;
    border-radius: 50%;
  }

  .words2 {
    margin-top: 1%!important;
    margin-left: 5%!important;
    margin-right: 5%!important;
    font-weight: bold;
    text-align: justify;
    color: black;
    font-size: 12.5px;
  }
 

}

.logo1{
  margin-top: 10%;
  height: 25vh;
  width: 100vw!important;
  display: flex;
  padding-right: 5%;
}

.design{
  border-radius: 50%;
  margin-left: 5%;
  width: 100vw;
 
}

.mob{
  display: flex;
  width: 90vw;


}
@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}


.words{
 width: 50vw;
 /* background-color: aqua; */
 margin-left: 4%;
 text-align: center;
}



.card-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  max-height: calc(100vh - 20px);
  /* Adjust the height as needed */
  overflow-y: auto;
}

.left-cards,
.right-cards {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.ai-img{
  margin-top: 10%;
  margin-left: 10%;
  border-radius: 50%;
}

.words2{
  margin-top: -14%;
  margin-left: 40%;
  margin-right: 10%;
  font-weight: bold;
  text-align: justify;
  color: black;
}