.p1 {
    height: 100vh;
    width: 100vw;
    background-color: #ba9aca;
    border-top-left-radius: 9%;
    border-top-right-radius: 9%;
}

.Screen1 {
    height: 500px;
    width: 255px;
    margin-left: 10%;
    margin-top: 8%;
}

.word1 {
    margin-left: 10%;
    margin-top: 20%;
    font-weight: 500;
    font-size: 40px;
    color: black;
    text-align: left;
}

.p0 {
    font-weight: 100;
    font-size: 20px;
    color: black;
}


.p2 {
    height: 100vh;
    width: 100vw;
    background-color: #ba9aca;
}

.Screen2 {
    height: 500px;
    width: 255px;
    margin-right: 80%;
}

.word2 {
    margin-left: 10%;
    margin-top: 20%;
    font-weight: 500;
    font-size: 40px;
    color: black;
}

.p00 {
    font-weight: 100;
    font-size: 20px;
    color: black;
}

.pp{
    height: 25vh;
    width: 100vw;
}

.ww{
    margin-left: 40%;
    margin-top: 2%;
    font-size: 25px;
}


@media (max-width: 780px) {
    .Screen1 {
        margin-left: 20%;
        margin-right: 10px;
        width: 300%;
        max-width: 240px;
        max-height: 450px;
        margin-top: 10%;
    }

    .part1 {
        display: flex;
        flex-direction: column;
    }

    .Screen2 {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        max-width: 300px;
    }

    .part2 {
        display: flex;
        flex-direction: column-reverse;
    }

    .word1,
    .word2 {
        margin-left: 5%;
        margin-top: 10%;
        font-size: 24px;
    }

    .p0,
    .p00 {
        font-size: 16px;
    }

    .p1,.p2 {
        border-radius: 0% 20% 0 20%;
    }

    .pp {
        height: 20vh;
      }
      
      .ww {
        margin-left: 17%;
        margin-top: 5%;
        font-size: 22px;
      }
    
      .download-button {
        padding: 5px 40px!important;
        font-size: 20px!important;
        margin-left: 22%!important;
        margin-top: 5%!important;
        overflow-y: hidden!important;
      }
}


.download-button {
    display: inline-block;
    background-color: #ba9aca;
    color: white;
    padding: 10px 50px;
    border-radius: 15px;
    text-decoration: none;
    font-weight: bold;
    font-size: 25px;
    margin-left: 40%;
    margin-top: 2%;
    cursor: pointer;
    animation: pop 2.5s infinite;
  }

  @keyframes pop {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  