/* .contactcard {
 

} */



.cc{
  margin-right: 2%;
}




/* .wrapper {
    display: flex;
  }
  
  .contact-card {
    width: auto;
    margin-right: 16px;
    height: 250px;
  }
  
  .image {
    height: 200px;
  }
  
  .title {
    font-weight: bold;
  }
  
  .text {
    color: #555;
  } */
  
  .image {
   display: flex;
   padding-left: 8%;
}
  .button{
    border-radius: 35px;
    width: 35px;
    height: 35px;
    display: inline-block;
    align-items: center;
    justify-content: space-around;
    padding-left: 5px;
    margin: 5px;
}  

.container2{
  padding-top: 10%
}

.card{
  overflow-y: hidden;
  align-items: center;
}

.title{
  overflow-y: hidden;
}

.box{
  width: 100vw;
  height: 100vh;
}
  
@media screen and (max-width: 780px){
  .phone{
    display: flex!important;
    flex-direction: column;
    align-items: center;

  }
}

.icon{
  text-align: center;
    font-size: 1.5rem;
    width: 1.5rem;
    color: white;
    display: inline-block;
    align-items: center;
    justify-content: space-around;
}

.contactcard {
  margin-right: 2%;

}




/* .Wrapper{
    margin: 2rem;
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr;
    justify-content: center;
}

.contact-card{
    box-shadow: 0 0 10px 5px gray;
    border-radius: 5px;
    min-width: 18rem;
    display: flex;
    padding: 16px;
    margin-right: 16px;


    &__Img{
        width: auto;
        height: auto;
        object-fit: cover;
    }
} */